import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import * as CONST from "../../components/const";
// import bg from "../../images/isms/bg.jpg";
// import bg_b from "../../images/isms/bg_b.png";
const bg_b = "../../images/inquiry/Inquiry_org.png";
const bg = "../../images/isms/ISMS_org.png";
const isms = "../../images/isms/isms_logo.png";
const consulting = "../../images/isms/consulting.png";
const csirt = "../../images/isms/csirt.png";
const csirtmail = "../../images/isms/csirtmail.png";

function IndexPage() {
  const STATIC_IMAGE_PLACEHOLDER = "blurred"; // blurred tracedSVG none
  const TABLE_ROW_CLASS = CONST.TABLE_ROW_CLASS + " border-dotted xl:text-sm";
  const TABLE_TITLE_CLASS = CONST.TABLE_TITLE_CLASS + " xl:leading-7";
  const TABLE_TEXT_CLASS = CONST.TABLE_TEXT_CLASS + " xl:leading-7";

  const H5_CLASS = CONST.H5_CLASS + " pt-10 pb-5 xl:pt-20 xl:pb-10";
  return (
    <Layout>
      <SEO
        description="ALPHA WAVEの情報セキュリティに関する取り組みについてです。ISMSとセキュリティーアクションの詳細をご覧ください。"
        title="Isms"
      />

      <section className={CONST.SECTION_P_AREA_CLASS + " bg-gray-light"}>
        <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="absolute w-full z-10 top-0 right-0" src={bg} />
        <h1 className={CONST.H1_CLASS + " font-light xl:font-normal 3xl:tracking-normal z-20 w-full"}><span className="inline-block">ISMSへの</span><span className="inline-block">取り組み</span></h1>
        <div className="w-full xl:w-9/12 font-light  z-20">
          <p>2006年7月6日付けで、情報セキュリティマネジメントシステム ISO/IEC 27001:2005 の認証を取得しました。</p>
          <p>2015年6月 ISO/IEC 27001:2013へ移行が完了致しました。</p>
          <p>2024年6月 ISO/IEC 27001:2022へ移行が完了致しました。</p>
        </div>
        <div className="w-full xl:w-3/12 p-5 z-20">
          <StaticImage quality="100" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="float-right" src={isms} />
        </div>
        <div className={TABLE_ROW_CLASS}>
          <div className={TABLE_TITLE_CLASS}>認証基準</div>
          <div className={TABLE_TEXT_CLASS}>ISO/IEC 27001:2022</div>
        </div>
        <div className={TABLE_ROW_CLASS}>
          <div className={TABLE_TITLE_CLASS}>認証登録番号</div>
          <div className={TABLE_TEXT_CLASS}>JQA-IM1605</div>
        </div>
        <div className={TABLE_ROW_CLASS}>
          <div className={TABLE_TITLE_CLASS}>認証登録範囲</div>
          <div className={TABLE_TEXT_CLASS}>
            <p>コンピュータソフトウェアの開発、運用及び保守</p>
            <p>情報システムのネットワーク構築、保守</p>
          </div>
        </div>
        <div className={TABLE_ROW_CLASS}>
          <div className={TABLE_TITLE_CLASS}>審査登録機関</div>
          <div className={TABLE_TEXT_CLASS}>一般財団法人 日本品質保証機構</div>
        </div>
        <div className={TABLE_ROW_CLASS}>
          <div className={TABLE_TITLE_CLASS}>情報セキュリティの目的</div>
          <div className={TABLE_TEXT_CLASS}>株式会社アルファ・ウェーブおよび関連会社グループは、保有する情報資産の消失、盗難、不正使用、漏洩などを防止し、機密性、完全性、可用性を維持することを組織の目的とします。</div>
        </div>
        <div className={TABLE_ROW_CLASS}>
          <div className={CONST.PC_SHOW_CLASS + TABLE_TITLE_CLASS}>
            <p>お客様との契約、</p>
            <p>及び法的要件の遵守</p>
          </div>
          <div className={CONST.PC_HIDDEN_CLASS + TABLE_TITLE_CLASS} >
            <p>お客様との契約、及び法的要件の遵守</p>
          </div>
          <div className={TABLE_TEXT_CLASS}>従業者は、お客様との契約上のセキュリティ義務、個人情報保護法や不正アクセス禁止法などの関連法令およびその他の規範を遵守します。</div>
        </div>
        <div className={TABLE_ROW_CLASS}>
          <div className={CONST.PC_SHOW_CLASS + TABLE_TITLE_CLASS}>
            <p>情報セキュリティに</p>
            <p>おける責任の明確化</p>
          </div>
          <div className={CONST.PC_HIDDEN_CLASS + TABLE_TITLE_CLASS}>
            <p>情報セキュリティにおける責任の明確化</p>
          </div>
          <div className={TABLE_TEXT_CLASS}>
            <p>(1)　従業者は、社内で定められたセキュリティ規定の実施責任を持ちます。</p>
            <p>(2)　従業者は、定期的に情報セキュリティ教育・訓練を受けます。</p>
            <p>(3)　従業者は、情報セキュリティ規定の違反や弱点について、正しい報告手順に従って社内セキュリティチームに報告します。</p>
            <p>(4)　所属長は、自組織の従業者に対して正しいセキュリティ規定を理解させ、遵守させる責任を持ちます。</p>
            <p>(5)　株式会社アルファ・ウェーブおよび関連会社グループは、報告された情報セキュリティ規定の違反や弱点を調査し、必要に応じて改善処置を行ないます。</p>
            <p>(6)　株式会社アルファ・ウェーブおよび関連会社グループは、セキュリティに関する方針および規定類を、定期的または事業環境の変化等によって適宜見直します。</p>
            <p>(7)　株式会社アルファ・ウェーブおよび関連会社グループは、社内コミュニケーションを大切にし、活性化を図ることが情報セキュリティ意識を高め、健全な事業活動を推進できると考え努力します。</p>
          </div>
        </div>
        <div className="w-full  z-30">
          <div className="float-right text-left mt-4 xl:mt-10">
            <p>以　上</p>
            <p>2022年8月8日</p>
            <p>株式会社アルファ・ウェーブ</p>
            <p>代表取締役社長　奥田史一</p>
          </div>
        </div>
      </section>

      <section className={CONST.SECTION_P_AREA_CLASS + " relative"} id="security">
        <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full xl:w-10/12 absolute top-112 xl:top-48 right-0 z-0" src={bg_b} />
        <div className="w-full z-20">
          <h1 className="text-3xl  sm:text-5xl 3xl:text-5xl mb-8 xl:mb-16 font-light xl:font-normal 3xl:tracking-normal"><span className="inline-block">セキュリティー</span><span className="inline-block">アクション</span></h1>
          <p>セキュリティ対策ツールを導入しただけでは、サイバー攻撃からの脅威を全て防ぐ事はできません。</p>
          <p>セキュアな行動が当たり前に取れる様に、普段からセキュリティ意識を持つことが大事です。</p>
          <p className={CONST.LINE_SPACING_PB_CLASS}>「人が主役でツールがそれをサポートする」そんなセキュリティソリューションを提供していきます。</p>
          <p>弊社は、お客様のビジネスを守るためにアクションしています。</p>

          <h5 className={H5_CLASS}><span className="inline-block">サイバー</span><span className="inline-block">セキュリティ</span><span className="inline-block">ソリューション</span></h5>
          <p>現状を把握するセキュリティコンサルティングから全てが始まります。</p>
          <p>コンサルティングメニュー</p>
          <div className="flex flex-wrap flex-row">
            <div className="pl-5 w-full xl:w-3/12">
              <p>管理：マネジメントサポート</p>
              <p>対策：脅威への予防措置</p>
              <p>検知：監視分析</p>
              <p>運用：検証と復旧</p>
            </div>
            <div className="pl-3 w-full xl:w-9/12">
              <StaticImage quality="100" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="" src={consulting} />
            </div>
          </div>

          <h5 className={H5_CLASS}>トピックス＆ニュース</h5>
          <div className={CONST.LINE_SPACING_PB_CLASS}>
            <p>脆弱性情報（JVN）</p>
            <a href="https://jvn.jp/">
              <img src="https://jvn.jp/img/banner_0323.jpg" alt="JVN" width="91" height="31" border="0" />
            </a>
          </div>
          <div>
            <p>セキュリティレポート</p>
            <a className="pl-5" href="https://www.jpcert.or.jp/research/">JPCERT CC「研究・調査レポート」</a>
          </div>

          <h5 className={H5_CLASS}>弊社の主な取り組み</h5>
          <p className="text-lg">社内</p>
          <div className="p-5">
            <p>セキュリティテスト実施</p>
            <p className={CONST.LINE_SPACING_PB_CLASS}>社内のセキュリティの啓蒙活動の一環として、全社員必須のセキュリティテストを実施</p>
            <p>社内CTF for ウルトラスーパービギナーズ開催</p>
            <p>１８チーム総勢約６０人の若手社員が参加し、年間を通しての定期開催で優勝を競うイベントを開催</p>
          </div>
          <p className="text-lg">社外</p>
          <div className="p-5">
            <p>Hardening Project競技会参加</p>
            <p className={CONST.LINE_SPACING_PB_CLASS}>堅牢化技術の向上への実践的な競技形式に惹かれ、サイバーセキュリティの学び場として、2015年11月の大会から参加しています。</p>
            <p>＜過去の成績＞</p>
            <p>2015年11月 Hardening 10 Value Chain：４位　／　全１０チーム</p>
            <p>2016年06月 Hardening 100 Value x Value：３位　／　全１２チーム</p>
            <p className={CONST.LINE_SPACING_PB_CLASS}>2017年06月 Hardening 1010 Cash Flow：２位　／　全１５チーム</p>
            <p>各種セキュリティセミナーや勉強会への参加</p>
          </div>

          <h5 className={H5_CLASS}>ＣＳＩＲＴチーム</h5>
          <p>活動</p>
          <p className={CONST.LINE_SPACING_PB_CLASS + " p-5"}>サイバー攻撃や脆弱性に関する情報を収集し、社内およびお客様への注意喚起や予防措置を行う他、セキュリティインシデント発生時には対応支援、復旧支援などのインシデントレスポンスも担当します。</p>
          <p>2018年8月 日本シーサート協議会へ正式加盟致しました。</p>
          <div className='{CONST.LINE_SPACING_PB_CLASS}'>
            <StaticImage quality="100" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} src={csirt} />
          </div>
          <p className="mt-5">連絡先</p>
          <StaticImage quality="100" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="" src={csirtmail} />
        </div>
      </section>
    </Layout>
  );
}

export default IndexPage;
